import React, { Component, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Table } from 'semantic-ui-react'

import DigitalImage from '../../assets/img/digital.png';
import MagImage from '../../assets/img/magazine.png';
import BundleImage from '../../assets/img/bundle.png';
import hex2rgba from 'hex2rgba';

export const PurchaseTableHeader = (props) => {
  const { t } = useTranslation();
  const style = {
    tableHeader: {
      backgroundColor: props.publication.backgroundColour,
      padding: '2em',
      fontSize: '17px',
      color: '#fff',
    },
  };

  return  <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              ></Table.HeaderCell>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              >
                {t('subscription')}
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              >
                {t('price')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
}

const PURCHASE_TABLE_ROW_TYPE = {
    DIGITAL: 'DIGITAL',
    PRINT: 'PRINT',
    BUNDLE: 'BUNDLE'
  }
  
export const PurchaseTableGenericRow = (props) => {
    const bg05 = hex2rgba(
      props.publication.backgroundColour,
      0.05,
    )
    const bg15 = hex2rgba(
      props.publication.backgroundColour,
      0.05,
    )
  
    const style = {
      changecolor: {
        color: '#828282',
        fontSize: '18px',
      },
      tableContentdigital: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: bg05,
      },
      tableContentprint: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: '#f3f3f3',
      },
      tableContentbundle: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: bg15,
      },
    };
  
    const purchase_type_key = props.keyTarget[1]
    let purchase_table_row_type = null
    if( purchase_type_key == 'P' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.PRINT
    if( purchase_type_key == 'D' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.DIGITAL
    if( purchase_type_key == 'B' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.BUNDLE
  
    let styleLocal = null
    let imgLocal = null
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.DIGITAL ){
      styleLocal = style.tableContentdigital
      imgLocal = MagImage
    }
      
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.PRINT ){
      styleLocal = style.tableContentprint
      imgLocal = DigitalImage
    }
      
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.BUNDLE ){
      styleLocal = style.tableContentbundle
      imgLocal = BundleImage
    }
    
  
    const imgWidth = 
      purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.BUNDLE? '100px': '50px'
  
    if( props.price == null || props.price.length == 0 ) return null
  
    if( props.isFree != null ){
      if( props.isFree === false ) return null
    }
    const value = props.keyTarget + props.price
    return <Table.Row>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <img src={imgLocal} style={{ width: imgWidth }} alt='' />
              </Table.Cell>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <Form.Field>
                  <Checkbox
                    style={style.changecolor}
                    radio
                    label={props.label}
                    name='checkboxSelection'
                    value={props.keyTarget + props.price}
                    checked={props.checkboxSelection == value}
                    onChange={(e,{value}) => props.onChange(e, {value})}
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <span style={{ fontSize: '18px' }}>{props.isFree==null?props.currency:''}{props.price}</span>
              </Table.Cell>
            </Table.Row>
  }

export const PurchaseTable = (props) => {
  return <div style={{ padding: '25px' }}>
          <Table celled>
            <PurchaseTableHeader publication={props.publication} />
            <Table.Body>
              {props.purchase_list.map( (item, idx) => 
                {
                  // Price not configured
                  if( item.price == null || item.price.length == 0 ) return null;

                  // Treating free
                  if( item.isFree != null && item.isFree === false) return null;

                  
                  return <PurchaseTableGenericRow key={idx}
                          checkboxSelection={props.checkboxSelection}
                          publication={props.publication}
                          onChange={(e, {value}) => props.onChange(e, {value})}
                          currency={props.currency}
                          {...item}
                        />
                }
              )}
            </Table.Body>
            <Table.Footer></Table.Footer>
          </Table>
        </div>
}